<template>
    <el-row class="warp">
        <el-col :span="24" class="warp-breadcrum">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/' }"><b>首页</b></el-breadcrumb-item>
                <el-breadcrumb-item>内容管理</el-breadcrumb-item>
                <el-breadcrumb-item>景点</el-breadcrumb-item>
            </el-breadcrumb>
        </el-col>
        <el-col :span="24" class="warp-main">

            <el-col :span="24" class="toolbar" style="padding-bottom: 0px;">
                <el-form :inline="true" :model="filters">
                    <el-form-item>
                        <el-select v-model="filters.type" placeholder="请选择类型" class="el-select-big" clearable>
                            <el-option
                                    v-for="item in typeArray"
                                    :key="item.value"
                                    :label="item.name"
                                    :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-input v-model="filters.name" placeholder="请输入名称" clearable></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" v-on:click="getSceneryListPage" icon="search">查询</el-button>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" v-on:click="goReset" icon="search">重置</el-button>
                    </el-form-item>
                    <el-form-item >
                        <el-button type="primary" icon="plus" v-if="permissionButton.add" @click="$router.push({path:'/scenery/add'})">新增</el-button>
                    </el-form-item>
                </el-form>
            </el-col>

            <el-table :data="scenery" highlight-current-row v-loading="listLoading" @selection-change="selsChange" style="width: 100%;" border>
                <el-table-column type="selection" width="50" align="center"></el-table-column>
                <el-table-column type="index" label="序号" width="60" align="center"></el-table-column>
                <el-table-column prop="type" label="类型" width="100" align="center" :formatter="formatType" sortable></el-table-column>
                <el-table-column prop="name" label="名称" min-width="400"></el-table-column>
                <el-table-column prop="indexImageUrl" label="列表缩略图片" width="130" align="center">
                    <template slot-scope="scope" >
                        <img v-if="scope.row.indexImageUrl" style="width: 89px; height: 40px" :src="downloadFileURL+scope.row.indexImageUrl+'/'"/>
                    </template>
                </el-table-column>
                <el-table-column prop="readNumber" label="阅读次数" width="120" align="center" sortable></el-table-column>
                <el-table-column prop="likeCount" label="点赞人数" width=120 align="center" sortable></el-table-column>
                <el-table-column prop="isTop" label="置顶" width="80" align="center" sortable>
                    <template slot-scope="scope">
                        <span v-bind:class="{temporary: (scope.row.isTop)==0}">{{scope.row.isTop==0?'是':scope.row.isTop==1?'否':''}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="recommended" label="推荐" width="80" align="center" sortable>
                    <template slot-scope="scope">
                        <span v-bind:class="{temporary: (scope.row.recommended)==0}">{{scope.row.recommended==0?'是':'否'}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="status" label="状态" width="80" align="center" sortable>
                    <template slot-scope="scope">
                        <span v-bind:class="{temporary: (scope.row.status)==1}">{{scope.row.status==0?'已发布':'草稿'}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="publisher" label="发布人" width="100" align="center"></el-table-column>
                <el-table-column prop="formatUpdateTime" label="修改时间" width="160" align="center" sortable>
                    <template slot-scope="scope">
                        <span>{{scope.row.formatUpdateTime ? scope.row.formatUpdateTime : "暂无更改"}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="formatCreateTime" label="创建时间" width="160" align="center" sortable></el-table-column>
                <el-table-column label="操作" width="220" align="center" fixed="right">
                    <template slot-scope="scope">
                        <el-button size="small" v-if="permissionButton.view" @click="$router.push({path:'/scenery/view',query:{id:scope.row.id}})">查看</el-button>
                        <el-button type="primary" size="small" v-if="permissionButton.edit" @click="$router.push({path:'/scenery/edit',query:{id:scope.row.id}})">编辑</el-button>
                        <el-button type="danger" @click="delScenery(scope.$index,scope.row)" size="small" v-if="permissionButton.del">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-col :span="24" class="table-footer">
                <el-button type="danger" size="small" @click="batchDeleteScenery" :disabled="this.sels.length===0" v-if="permissionButton.batchDel">批量删除</el-button>
                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :page-sizes="[10,15,20,25,50]" :page-size="pageSize" layout=" total, sizes, prev, pager, next, jumper" :total="total" style="float:right;"></el-pagination>
            </el-col>
        </el-col>
    </el-row>
</template>

<script>
    import {downloadFileURL} from '../../../api/system-api'
    import {reqGetSceneryListPage,reqDeleteScenery,reqBatchDeleteScenery} from '../../../api/scenery-api'
    import permission from '../../../common/permission';
    export default {
        data(){
            return{
                downloadFileURL:downloadFileURL,
                typeArray:[
                    {name:'宗教景点',value:'1'},
                    {name:'人文景点',value:'2'},
                    {name:'网红景点',value:'3'},
                    {name:'周边景点',value:'4'},
                ],
                filters:{
                    name:'',
                    type:'',
                },
                scenery:[],
                total:0,
                pageNum:1,
                pageSize:15,
                listLoading:false,
                sels:[],
                permissionButton:new permission.PermissionButton,
            }
        },
        methods:{
            handleCurrentChange(val) {
                this.pageNum = val;
                this.getSceneryListPage();
            },
            handleSizeChange(val) {
                console.log(`每页 ${val} 条`);
                this.pageSize=val;
                this.getSceneryListPage();
            },

            getSceneryListPage() {
                let para = {
                    pageNum: this.pageNum,
                    pageSize: this.pageSize,
                    name: this.filters.name,
                    type: this.filters.type
                };
                this.listLoading = true;
                reqGetSceneryListPage(para).then((res) => {
                    this.total = res.data.data.total;
                    this.scenery = res.data.data.records;
                    this.listLoading = false;
                })
            },
            selsChange: function (sels) {
                this.sels = sels;
            },
            goReset(){
                this.filters.name='';
                this.filters.type='';
                this.pageNum=1;
                this.getSceneryListPage();
            },

            formatType:function(row){
                if(row.type){
                    return row.type==='1'?'宗教景点':row.type==='2'?'人文景点':row.type==='3'?'网红景点':row.type==='4'?'周边景点':''
                }
            },

            // 删除
            delScenery: function(index, row) {
                this.$confirm('确认删除该记录吗?', '提示', {type: 'warning'}).then(() => {
                    this.listLoading = true;
                    let para = {id: row.id};
                    reqDeleteScenery(para).then((res) => {
                        if(res.data.code===1){
                            this.listLoading = false;
                            this.$message({
                                message: '删除成功',
                                type: 'success'
                            });
                            this.getSceneryListPage();
                        }else {
                            this.listLoading = false;
                            this.$message({
                                message: '删除失败',
                                type: 'error'
                            });
                            this.getSceneryListPage();
                        }
                    });
                }).catch(() => {
                });
            },
            // 批量删除
            batchDeleteScenery: function () {
                var ids = this.sels.map(item => item.id).toString();
                this.$confirm('确认删除选中记录吗？', '提示', {
                    type: 'warning'
                }).then(() => {
                    this.listLoading = true;
                    let para = {ids: ids};
                    reqBatchDeleteScenery(para).then((res) => {
                        if(res.data.code===1){
                            this.listLoading = false;
                            this.$message({
                                message: '删除成功',
                                type: 'success'
                            });
                            this.getSceneryListPage();
                        }else {
                            this.listLoading = false;
                            this.$message({
                                message: '删除失败',
                                type: 'error'
                            });
                            this.getSceneryListPage();
                        }
                    });
                }).catch(() => {

                });
            },
        },mounted(){
            this.getSceneryListPage();
            var permissionId = sessionStorage.getItem("permissionId");
            permission.getPermissionButton(permissionId,this.permissionButton);
        }
    }
</script>
