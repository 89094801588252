import axios from 'axios'
import {SERVER_HOST} from './../common/portConfig'
var instance = axios.create({
    headers: {
        'Authorization': ''
    }
});
instance.interceptors.request.use(
    config => {
        config.headers.Authorization = sessionStorage.getItem('Authorization');
        return config
    }, err => {
        return Promise.reject(err)
    });

//景点

export const reqGetSceneryListPage = params => { return instance.get(`${SERVER_HOST}/admin/scenery/list`,{ params: params }) };
export const reqAddScenery = params => { return instance.post(`${SERVER_HOST}/admin/scenery/add`, params) };
export const reqEditScenery = params => { return instance.post(`${SERVER_HOST}/admin/scenery/edit`, params) };
export const reqDeleteScenery= params => { return instance.delete(`${SERVER_HOST}/admin/scenery/delete/`+params.id, params) };
export const reqBatchDeleteScenery = params => { return instance.post(`${SERVER_HOST}/admin/scenery/batchdelete/`, params) };
export const reqGetSceneryOne = params => { return instance.get(`${SERVER_HOST}/admin/scenery/one`, { params: params }) };
